export const daolist = [
  {
    id: '1',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1242184851152928769%2FwG2eTAfD_normal.jpg',
    full_name: 'Uniswap',
    category: 'Protocol',
    aum: 3297518399,
    twl: 562397,
    date_founded: 'April 2018',
    governance_token_name: 'Uniswap',
    social_media: {
      twitter_handle: '',
      discord_link: 'https://discord.com/invite/FCfyBSbCU5',
      github_organization_handle: 'Uniswap',
      telegram_link: '',
    },
    governance_token_symbol: 'UNI',
    logo_link: 'https://uniswap.org/',
    description:
      'Uniswap empowers developers, liquidity providers and traders to participate in a financial marketplace that is open and accessible to all. We are committed to open source software and building on the decentralized web.',
    blockchain: 'Ethereum',
    tvl: 4.93,
  },
  {
    id: '2',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1152363392595714048%2FOVJu6nwQ_normal.png',
    full_name: 'Compound',
    category: 'Protocol',
    aum: 1554407179,
    twl: 156535,
    tvl: 11.15,
    date_founded: 'May 2011',
    governance_token_name: 'Compound',
    social_media: {
      twitter_handle: 'compoundfinance',
      discord_link: 'https://discord.com/invite/fq6JSPkpJn',
      github_organization_handle: 'compound-finance',
    },
    governance_token_symbol: 'COMP',
    logo_link: 'https://compound.finance',
    description:
      'Compound is an algorithmic, autonomous interest rate protocol built for developers, to unlock a universe of open financial applications.',
  },
  {
    id: '3',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1186270065085370368%2FJ1YJtvdI_normal.jpg',
    full_name: 'Aave',
    category: 'Protocol',
    aum: 766115566,
    twl: 248054,
    date_founded: 'May 2017',
    tvl: 13.41,
    governance_token_name: 'AAVE',
    social_media: {
      twitter_handle: 'AaveAave',
      discord_link: 'https://discord.com/invite/adPfquZDZc',
      github_organization_handle: 'aave',
    },
    governance_token_symbol: 'AAVE',
    logo_link: 'https://aave.com',
    description:
      'Aave is an Open Source and Non-Custodial protocol to earn interest on deposits and borrow assets',
  },
  {
    id: '4',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1392501414887313409%2FixijGeIo_normal.jpg',
    full_name: 'Badger',
    category: 'Protocol',
    aum: 655426161,
    twl: 35724,
    tvl: 0.74454,
    date_founded: 'August 2020',
    governance_token_name: 'Badger DAO',
    social_media: {
      twitter_handle: 'BadgerDAO',
      discord_link: 'https://discord.com/invite/xSPFHHS',
      github_organization_handle: 'Badger-Finance',
    },
    governance_token_symbol: 'BADGER',
    logo_link: 'https://badger.finance/',
    description:
      'DAO dedicated to building products and infrastructure to bring Bitcoin to DeFi.',
  },
  {
    id: '5',
    img: 'https://sushi.com/static/media/logo.dec926df.png',
    full_name: 'SushiChef',
    category: 'Protocol',
    aum: 262401473,
    twl: 110453,
    date_founded: 'September 2020',
    governance_token_name: 'SushiSwap',
    social_media: {
      twitter_handle: 'SushiSwap',
      github_organization_handle: 'sushiswap',
    },
    governance_token_symbol: 'SUSHI',
    logo_link: 'https://sushi.com',
    description:
      'A fully decentralized protocol for automated liquidity provision on Ethereum.',
    tvl: 4.52,
  },
  {
    id: '6',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1196456510424961025%2Fzacb-9pN_normal.jpg',
    full_name: 'Maker',
    category: 'Protocol',
    aum: 222927865,
    twl: 164381,
    tvl: 8.78,
    date_founded: 'April 2015',
  },
  {
    id: '7',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1220560374346461185%2FW1sQNVWo_normal.jpg',
    full_name: 'Curve Finance',
    category: 'Protocol',
    aum: 216227448,
    twl: 138611,
    tvl: 11.34,
    date_founded: 'January 2020',
  },
  {
    id: '8',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1154798570047967233%2FZINt8NSB_normal.jpg',
    full_name: 'Tornado',
    category: 'Protocol',
    description: 'Non-custodial anonymous transactions on Ethereum.',
    tvl: 0.69204,
    aum: 182427865,
    twl: 21236,
    date_founded: 'July 2019',
  },
  {
    id: '9',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1174715409100083200%2FRYCU7_dZ_normal.png',
    full_name: 'Balancer',
    category: 'Protocol',
    aum: 104728397,
    description: 'Balancer is a protocol for programmable liquidity.',
    governance_token_name: 'Balancer',
    governance_token_symbol: 'BAL',
    twl: 89435,
    date_founded: 'August 2019',
    tvl: 3.26,
  },
  {
    id: '10',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1368769440712597504%2FSgc5zYvO_400x400.jpg',
    full_name: 'API3',
    category: 'Protocol',
    aum: 103677441,
    twl: 25800,
    date_founded: 'August 2020',
  },
  {
    id: '11',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1298694547577462785%2Fm_ZIw4kb_400x400.jpg',
    full_name: 'BarnBridge',
    category: 'Protocol',
    aum: 95979024,
    twl: 21100,
    date_founded: 'August 2020',
  },
  {
    id: '12',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1356618871030108161%2FuS04dyYy_400x400.jpg',
    full_name: 'Alchemix',
    category: 'Protocol',
    aum: 95729079,
    twl: 36400,
    date_founded: 'August 2020',
  },
  {
    id: '13',
    img: 'https://index-dao.s3.amazonaws.com/owl.png',
    full_name: 'Index Coop',
    category: 'Protocol',
    aum: 41895539,
    twl: 18453,
    date_founded: 'October 2020',
  },
  {
    id: '14',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1226087357461487616%2FIq1CHt3I_400x400.jpg',
    full_name: 'mStable',
    category: 'Protocol',
    aum: 35662830,
    twl: 19400,
    date_founded: 'May 2019',
  },
  {
    id: '15',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1286204702338813952%2FX8fdf5p-_normal.png',
    full_name: 'Yearn Finance',
    category: 'Protocol',
    aum: 35050886,
    twl: 132091,
    date_founded: 'February 2020',
  },
  {
    id: '16',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1004666585292951554%2FWPfTLP3M_400x400.jpg',
    full_name: 'Nexus Mutual',
    category: 'Protocol',
    aum: 20107080,
    twl: 31000,
    date_founded: 'July 2017',
  },
  {
    id: '17',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1165560108744683520%2FxiLGl9-f_normal.png',
    full_name: 'DXdao',
    category: 'Service',
    aum: 17641618,
    twl: 5397,
    date_founded: 'August 2019',
  },
  {
    id: '18',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1407123088945254409%2F79YbMxeM_normal.jpg',
    full_name: 'Uniswap Grants',
    category: 'Grant',
    aum: 9250114,
    twl: 5251,
    date_founded: 'January 2021',
  },
  {
    id: '19',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1393144062312472578%2FK9L6xyFE_400x400.jpg',
    full_name: 'Decentral Games',
    category: 'Protocol',
    aum: 7494171,
    twl: 35100,
    date_founded: 'August 2016',
  },
  {
    id: '20',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1391810983979483136%2FsPY3LG9N_normal.jpg',
    full_name: 'Yam Finance',
    category: 'Service',
    aum: 5972408,
    twl: 24319,
    date_founded: 'August 2020',
  },
  {
    id: '21',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1282933852529291266%2FPRYeCD6A_normal.jpg',
    full_name: 'Aavegotchi',
    category: 'Protocol',
    aum: 4468823,
    twl: 38483,
    date_founded: 'July 2020',
  },
  {
    id: '22',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1220560374346461185%2FW1sQNVWo_normal.jpg',
    full_name: 'Curve Grants',
    category: 'Grant',
    aum: 2734593,
    twl: 0,
    date_founded: 'October 2020',
  },
  {
    id: '23',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1299241789669269508%2FHU-JT5GI_normal.png',
    full_name: 'Synthetix',
    category: 'Protocol',
    aum: 2105441,
    twl: 139026,
    date_founded: 'August 2017',
  },
  {
    id: '24',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1354022444953182209%2FfUvaichP_400x400.png',
    full_name: 'Synthetix Grants',
    category: 'Grant',
    aum: 2046132,
    twl: 991,
    date_founded: 'January 2021',
  },
  {
    id: '25',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1402707727323959308%2FAJK2Jlhg_normal.jpg',
    full_name: 'Compound Grants',
    category: 'Grant',
    aum: 1440693,
    twl: 1196,
    date_founded: 'February 2021',
  },
  {
    id: '26',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1389400052448247816%2FqsOU0pih_normal.jpg',
    full_name: 'BanklessDAO',
    category: 'Media',
    aum: 1332231,
    twl: 6337,
    date_founded: 'March 2020',
  },
  {
    id: '27',
    img: 'https://cdn.discordapp.com/icons/750784472697405646/2a6a7b50e5a771a5b2ea96c57bc4ae5c.png',
    full_name: 'Pickle Finance',
    category: 'Protocol',
    aum: 1034972,
    twl: 20300,
    date_founded: 'August 2020',
  },
  {
    id: '28',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1244615008723456000%2FhJLp_LsD_400x400.png',
    full_name: 'Hakka Finance',
    category: 'Protocol',
    aum: 651179,
    twl: 7900,
    date_founded: 'March 2020',
  },
  {
    id: '29',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1409925543114334208%2FEdjUdYhC_normal.jpg',
    full_name: 'Aave Grants',
    category: 'Grant',
    aum: 556966,
    twl: 2220,
    date_founded: 'April 2021',
  },
  {
    id: '30',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1335815288298721280%2Fle1nzlO6_normal.jpg',
    full_name: 'Yield Guild Games',
    category: 'Service',
    twl: 17174,
    date_founded: 'October 2020',
  },
  {
    id: '31',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1372145317827727361%2FLD457RFi_normal.jpg',
    full_name: 'VitaDAO',
    category: 'Service',
    twl: 3285,
    date_founded: 'February 2021',
  },
  {
    id: '32',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1341919394616770561%2FvcvBsmsA_normal.jpg',
    full_name: 'UniWhales',
    category: 'Service',
    twl: 7425,
    date_founded: 'March 2015',
  },
  {
    id: '33',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1392252472450822146%2FzNhrByCE_normal.jpg',
    full_name: 'Universe',
    category: 'Protocol',
    twl: 10243,
    date_founded: 'February 2021',
  },
  {
    id: '34',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1272388532607152128%2Fn-g5dgbN_normal.png',
    full_name: 'TheWIPmeetup',
    category: 'Social',
    twl: 2666,
    date_founded: 'May 2020',
  },
  {
    id: '35',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1167606228228423680%2F7H9uAs6S_normal.jpg',
    full_name: 'TheLAO',
    category: 'Investment',
    twl: 7956,
    date_founded: 'August 2019',
  },
  {
    id: '36',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1352642965278240769%2FKbcFqj3G_normal.png',
    full_name: 'Tellor',
    category: 'Protocol',
    twl: 23004,
    date_founded: 'April 2019',
  },
  {
    id: '37',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1371997400387088384%2FyJLNwIld_normal.jpg',
    full_name: 'Syndicate',
    category: 'Platform',
    twl: 10385,
    date_founded: 'August 2020',
  },
  {
    id: '38',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1317181705132904460%2FsRbFoqdV_normal.jpg',
    full_name: 'Stacker Ventures',
    category: 'Investment',
    twl: 2016,
    date_founded: 'September 2020',
  },
  {
    id: '39',
    img: 'https://squiggledao.com/static/sm.svg',
    full_name: 'SquiggleDAO',
    category: 'Collector',
    twl: 1608,
    date_founded: 'February 2021',
  },
  {
    id: '40',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1409275488607027204%2FPWkCxth2_normal.jpg',
    full_name: 'SongCamp',
    category: 'Social',
    twl: 873,
    date_founded: 'April 2021',
  },
  {
    id: '41',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1390523981225148416%2Fx421YwoV_normal.jpg',
    full_name: 'Sevens Foundation',
    category: 'Grant',
    twl: 4981,
    date_founded: 'December 2020',
  },
  {
    id: '42',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1343799330222657536%2FIUqDIi6B_400x400.jpg',
    full_name: 'Seed Club',
    category: 'Social',
    twl: 6031,
    date_founded: 'August 2020',
  },
  {
    id: '43',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1320861494112854018%2F7-zRzzum_400x400.jpg',
    full_name: 'rekt',
    category: 'Media',
    twl: 36200,
    date_founded: 'August 2020',
  },
  {
    id: '44',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1215352997179297792%2FAT2FCZhE_normal.jpg',
    full_name: 'RaribleDAO',
    category: 'Protocol',
    twl: 170196,
    date_founded: 'August 2019',
  },
  {
    id: '45',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1365586680883908610%2F_LAp7Dsb_normal.jpg',
    full_name: 'Rari Capital DAO',
    category: 'Protocol',
    twl: 24819,
    date_founded: 'May 2020',
  },
  {
    id: '46',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1226584267645341696%2FNQCsdXTC_normal.jpg',
    full_name: 'Raid Guild',
    category: 'Service',
    twl: 3915,
    date_founded: 'September 2019',
  },
  {
    id: '47',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1372563232850870274%2FaREQff_C_normal.jpg',
    full_name: 'Radicle',
    category: 'Social',
    twl: 15675,
    date_founded: 'October 2018',
  },
  {
    id: '48',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1413176754844446720%2FYxdfBunt_400x400.jpg',
    full_name: 'ProsperDAO',
    category: 'Social',
    twl: 61,
    date_founded: 'May 2021',
  },
  {
    id: '49',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1380603982205321217%2FEuVE1qKL_normal.jpg',
    full_name: 'Proof of Humanity',
    category: 'Social',
    twl: 4278,
    date_founded: 'September 2020',
  },
  {
    id: '50',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1399376178453090305%2FRJD82RrV_normal.jpg',
    full_name: 'PrimeDAO',
    category: 'Platform',
    twl: 3214,
    date_founded: 'July 2020',
  },
  {
    id: '51',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1313563688931069952%2FMtjyDDDh_normal.jpg',
    full_name: 'PoolTogether',
    category: 'Protocol',
    twl: 23411,
    date_founded: 'April 2019',
  },
  {
    id: '52',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1403453134668767236%2FpoMoHcUx_normal.jpg',
    full_name: 'PleasrDAO',
    category: 'Collector',
    twl: 12482,
    date_founded: 'March 2021',
  },
  {
    id: '53',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1404728139771744256%2F6sCZ8nqB_normal.jpg',
    full_name: 'PieDAO',
    category: 'Protocol',
    twl: 13422,
    date_founded: 'January 2019',
  },
  {
    id: '54',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1410051594125709312%2FjVA9NRiQ_normal.jpg',
    full_name: 'PartyDAO',
    category: 'Service',
    twl: 1481,
    date_founded: 'April 2021',
  },
  {
    id: '55',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1374452255332573184%2FH5WRfc_d_normal.jpg',
    full_name: 'Orca',
    category: 'Platform',
    twl: 1168,
    date_founded: 'December 2020',
  },
  {
    id: '56',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1350145905438175234%2FojsHt9O6_normal.jpg',
    full_name: 'OPOLIS',
    category: 'Service',
    twl: 2577,
    date_founded: 'March 2012',
  },
  {
    id: '57',
    img: 'https://cdn.discordapp.com/icons/798328113087119371/a_61c7cca092fbe054bfecd6c1c636c5f8.png?size=128',
    full_name: 'OlympusDAO',
    category: 'Protocol',
    twl: 12876,
    date_founded: 'January 2021',
  },
  {
    id: '58',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1349800663983779840%2FRGzpFDrE_400x400.jpg',
    full_name: 'NeptuneDAO',
    category: 'Service',
    twl: 1825,
    date_founded: 'January 2021',
  },
  {
    id: '59',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1407498143781040128%2Ftd2ufpfd_normal.png',
    full_name: 'Myco',
    category: 'Service',
    twl: 810,
    date_founded: 'November 2020',
  },
  {
    id: '60',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1396646800119975936%2FPpwj-MMS_normal.jpg',
    full_name: 'MUSE0',
    category: 'Collector',
    twl: 638,
    date_founded: 'May 2021',
  },
  {
    id: '61',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1372883007695060992%2F3CzDQZV2_normal.jpg',
    full_name: 'MolochDAO',
    category: 'Grant',
    twl: 6560,
    date_founded: 'February 2019',
  },
  {
    id: '62',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1361215809469771776%2F5a1H1sqV_normal.jpg',
    full_name: 'Mint Fund',
    category: 'Grant',
    twl: 5153,
    date_founded: 'February 2021',
  },
  {
    id: '63',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1219326469324697600%2FVbC_m_QR_normal.jpg',
    full_name: 'MetaGame',
    category: 'Social',
    twl: 2855,
    date_founded: 'September 2019',
  },
  {
    id: '64',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1410045044027912192%2FzIm2gPFU_normal.jpg',
    full_name: 'MetaFactory',
    category: 'Service',
    twl: 8443,
    date_founded: 'October 2019',
  },
  {
    id: '65',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1234120671413731328%2FYoa169RO_normal.jpg',
    full_name: 'MetaCartel Ventures',
    category: 'Grant',
    twl: 8518,
    date_founded: 'September 2019',
  },
  {
    id: '66',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1156297966233067521%2FRt2YFQgt_normal.jpg',
    full_name: 'MetaCartel',
    category: 'Investment',
    twl: 8594,
    date_founded: 'July 2018',
  },
  {
    id: '67',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1266510169199964160%2FkyaYogrM_normal.jpg',
    full_name: 'Meta Gamma Delta',
    category: 'Social',
    twl: 1051,
    date_founded: 'February 2020',
  },
  {
    id: '68',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1403786927673774080%2FZgVuisG__normal.jpg',
    full_name: 'MeebitsDAO',
    category: 'Collector',
    twl: 2334,
    date_founded: 'May 2021',
  },
  {
    id: '69',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1408282325742358530%2FbC_3xI30_400x400.jpg',
    full_name: 'Llama',
    category: 'Service',
    twl: 1180,
    date_founded: 'August 2020',
  },
  {
    id: '70',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1314525613881995264%2FpGDedFg0_normal.png',
    full_name: 'Lido',
    category: 'Protocol',
    twl: 27525,
    date_founded: 'October 2020',
  },
  {
    id: '71',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1376348470999724035%2FnkC7Sf_2_normal.jpg',
    full_name: 'LexDAO',
    category: 'Service',
    twl: 1569,
    date_founded: 'November 2019',
  },
  {
    id: '72',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1406023358714634240%2FPkAGiFDN_normal.jpg',
    full_name: 'Kraus House DAO',
    category: 'Social',
    twl: 284,
    date_founded: 'May 2021',
  },
  {
    id: '73',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1395750640245088260%2FeoBASfMQ_normal.png',
    full_name: 'Komorebi Collective',
    category: 'Investment',
    twl: 1119,
    date_founded: 'April 2021',
  },
  {
    id: '74',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1235001742716047360%2Fz8n7Rb13_normal.jpg',
    full_name: 'KeeperDAO',
    category: 'Protocol',
    twl: 21438,
    date_founded: 'July 2019',
  },
  {
    id: '75',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1379484940719505412%2F-Ga43mBt_normal.jpg',
    full_name: 'Jenny Metaverse DAO',
    category: 'Collector',
    twl: 11913,
    date_founded: 'April 2021',
  },
  {
    id: '76',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1289942453819252742%2FM363P_Pk_normal.png',
    full_name: 'JammSession',
    category: 'Social',
    twl: 481,
    date_founded: 'August 2020',
  },
  {
    id: '77',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1350488861089128456%2FkHRonsI-_normal.jpg',
    full_name: 'Inverse Finance',
    category: 'Protocol',
    twl: 12047,
    date_founded: 'December 2020',
  },
  {
    id: '78',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1407454373203021831%2Fou1yNJg5_normal.jpg',
    full_name: 'HoneyDAO',
    category: 'Service',
    twl: 946,
    date_founded: 'April 2021',
  },
  {
    id: '79',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1401979943626575873%2FIEyK8aDX_normal.jpg',
    full_name: 'HERSTORY',
    category: 'Collector',
    twl: 2224,
    date_founded: 'May 2020',
  },
  {
    id: '80',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1389684414133805063%2F8CupXj3d_normal.jpg',
    full_name: 'hDAO',
    category: 'Protocol',
    twl: 1754,
    date_founded: 'May 2021',
  },
  {
    id: '81',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1060558512068030464%2Fwun6fV4T_normal.jpg',
    full_name: 'Global Coin Research',
    category: 'Media',
    twl: 3919,
    date_founded: 'April 2018',
  },
  {
    id: '82',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1405178063596318721%2FyZmYeTXT_normal.png',
    full_name: 'Gitcoin',
    category: 'Protocol',
    twl: 51591,
    date_founded: 'April 2017',
  },
  {
    id: '83',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1342358166877720577%2FxRGEXmQN_normal.png',
    full_name: 'Friends With Benefits',
    category: 'Social',
    twl: 5163,
    date_founded: 'December 2020',
  },
  {
    id: '84',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1335004082503700481%2F0Hj7q-5w_400x400.png',
    full_name: 'Free Company',
    category: 'Investment',
    twl: 612,
    date_founded: 'August 2020',
  },
  {
    id: '85',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1293402536427139072%2FN2kir6jx_normal.jpg',
    full_name: 'Forefront',
    category: 'Media',
    twl: 3568,
    date_founded: 'January 2019',
  },
  {
    id: '86',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1309614351096127488%2FTdNyjlBL_normal.jpg',
    full_name: 'Flamingo',
    category: 'Investment',
    twl: 13498,
    date_founded: 'September 2020',
  },
  {
    id: '87',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1391125460810076170%2FwCPb0b88_normal.png',
    full_name: 'FingerprintsDAO',
    category: 'Collector',
    twl: 1694,
    date_founded: 'March 2021',
  },
  {
    id: '88',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1401796429916561412%2FGPazF27y_normal.jpg',
    full_name: 'FiatLuxDAO',
    category: 'Social',
    twl: 1039,
    date_founded: 'June 2021',
  },
  {
    id: '89',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1327603831979577348%2FRyx8ex9s_normal.jpg',
    full_name: 'DuckDAO Incubator',
    category: 'Investment',
    twl: 96630,
    date_founded: 'August 2020',
  },
  {
    id: '90',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1394650708113305600%2FmXTyCR4L_normal.jpg',
    full_name: 'dOrg',
    category: 'Service',
    twl: 1501,
    date_founded: 'March 2019',
  },
  {
    id: '91',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1399699943422582785%2FGMBQYUD6_normal.jpg',
    full_name: 'DoinGud',
    category: 'Service',
    twl: 6211,
    date_founded: 'March 2020',
  },
  {
    id: '92',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1110943075805872128%2FmE5l9TiV_400x400.png',
    full_name: 'Deep Work',
    category: 'Service',
    twl: 590,
    date_founded: 'February 2019',
  },
  {
    id: '93',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1034890446240006145%2FWGoEtcqK_normal.jpg',
    full_name: 'Decred',
    category: 'Protocol',
    twl: 47030,
    date_founded: 'February 2014',
  },
  {
    id: '94',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1405600548934537216%2FT2idgXL0_400x400.jpg',
    full_name: 'DarkstarDAO',
    category: 'Media',
    twl: 308,
    date_founded: 'June 2021',
  },
  {
    id: '95',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F932589128125943808%2F3Lmhssv9_normal.jpg',
    full_name: 'DaoStack',
    category: 'Platform',
    twl: 8217,
    date_founded: 'September 2016',
  },
  {
    id: '96',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1370501762499686405%2FjN3kkqke_normal.png',
    full_name: 'DaoHaus',
    category: 'Platform',
    twl: 7075,
    date_founded: 'August 2019',
  },
  {
    id: '97',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1328943304789123073%2FYmn7mIk6_normal.jpg',
    full_name: 'Creator Cabins',
    category: 'Social',
    twl: 962,
    date_founded: 'November 2020',
  },
  {
    id: '98',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1283747298573381633%2Fso8jjWOm_normal.jpg',
    full_name: 'Cream Finance',
    category: 'Protocol',
    twl: 56316,
    date_founded: 'June 2020',
  },
  {
    id: '99',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1038074862974189569%2FAA1bsW-8_normal.jpg',
    full_name: 'Colony',
    category: 'Platform',
    twl: 12800,
    date_founded: 'July 2009',
  },
  {
    id: '100',
    img: 'https://pbs.twimg.com/profile_images/1418082579379490818/EwYTIRZ7_400x400.jpg',
    full_name: 'Bright Moments Gallery',
    category: 'Social',
    twl: 369,
    date_founded: 'March 2021',
  },
  {
    id: '101',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1382957224448946180%2FTSp-Iayp_normal.jpg',
    full_name: 'Bored Ape Yacht Club',
    category: 'Social',
    twl: 28172,
    date_founded: 'April 2021',
  },
  {
    id: '102',
    img: 'https://pbs.twimg.com/profile_images/1420975498717372419/yoHZGHp7_400x400.jpg',
    full_name: 'BitDAO',
    category: 'Investment',
    twl: 3437,
    date_founded: 'April 2021',
  },
  {
    id: '103',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1377076644104581121%2Fkncyiy4x_normal.jpg',
    full_name: 'BeetsDAO',
    category: 'Collector',
    twl: 1087,
    date_founded: 'March 2021',
  },
  {
    id: '104',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1394316748116144134%2FxDV_bhdA_normal.jpg',
    full_name: 'BaconDAO',
    category: 'Service',
    twl: 9824,
    date_founded: 'February 2021',
  },
  {
    id: '105',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1387446260618219525%2FkRKidHes_normal.jpg',
    full_name: 'Audius',
    category: 'Protocol',
    twl: 52155,
    date_founded: 'July 2018',
  },
  {
    id: '106',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1406414087873986565%2FuL_w9skB_normal.jpg',
    full_name: 'Audacity Fund',
    category: 'Investment',
    twl: 825,
    date_founded: 'April 2021',
  },
  {
    id: '107',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1356647511520337921%2F7mHNl7X__normal.jpg',
    full_name: 'Aragon',
    category: 'Platform',
    twl: 85209,
    date_founded: 'February 2017',
  },
  {
    id: '108',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1395924690216968192%2F_CgsxOhz_normal.jpg',
    full_name: 'AladdinDAO',
    category: 'Service',
    twl: 2443,
    date_founded: 'March 2021',
  },
  {
    id: '109',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1276286557494837248%2FSgXEhvrc_normal.png',
    full_name: '1Hive',
    category: 'Service',
    twl: 4551,
    date_founded: 'May 2018',
  },
  {
    id: '110',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1337448316993478660%2FQKTkZZvw_400x400.png',
    full_name: 'AngelDAO',
    category: 'Investment',
    twl: 735,
    date_founded: 'December 2020',
  },
  {
    id: '111',
    img: 'https://daolist.fyi/nextimg/https%3A%2F%2Fpbs.twimg.com%2Fprofile_images%2F1296271529810960384%2F6s2QXz6y_400x400.jpg',
    full_name: 'Crypto Seed Partners',
    category: 'Investment',
    twl: 50,
    date_founded: 'August 2020',
  },
]
